<template>
  <div>
    <el-dialog
      title="动态资讯"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      @close="emitClose"
    >
      <el-form
        :model="dataForm"
        label-position="right"
        ref="formRules"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          label="动态标题："
          prop="title"
        >
          <el-input v-model="dataForm.title"></el-input>
        </el-form-item>
        <el-form-item label="" prop="content">
          <UEeditor
            :setDataStr="dataForm.content"
            @change="initDtaForm"
          />
        </el-form-item>
        <el-form-item label="类别" prop="type">
             <el-select  v-model="dataForm.type" placeholder="请选择类别" 
                  style="width: 100%;">
                  <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
                  </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="发布状态：" >
          <el-switch
            v-model="dataForm.status"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排序值：" prop="sort">
          <el-input v-model="dataForm.sort" type="Number"></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="emitClose">取 消</el-button>
        <el-button
          type="primary"
          @click="submit('formRules')"
        >确 定</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>



import UEeditor from "@/components/UEeditor";
import { crmUrl } from "@/utils/helper.js";

export default {
  components: {
    UEeditor
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataForm: {
      },
      typeList:[
        {
          label:'我要接单',
          value:1
        },
        {
          label:'我要发单',
          value:2
        }
      ],
      dialogFormVisible: this.isVisible,
      rules: {
        title: [{ required: true, message: "请输入标题名称", trigger: "blur" }],
        content:[{ required: true, message: "请输入内容", trigger: "blur"}],
        sort:[{ required: true, message: "请输入排序值", trigger: "blur" }],
        type:[{ required: true, message: "类别不能为空", trigger: "blur" }]
      },
    };
  },
  mounted() {

  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val;
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    initDtaForm(e) {
      this.dataForm.content = e
    },
    emitClose() {
      this.dataForm = {}
      this.$emit("close");
    },
    fetchDetail() {
      let str = crmUrl()
      this.$axios({
          url: `${str}/api/v1/sharePlatform/helpCenterArticle`,
          method: 'get',//可以省略 不写默认是get
          params:{id:this.id},
        }).then(res => {
           if(res.data.code==0){
            this.dataForm = res.data.data
           }
            console.log("详情",res)
        })
  
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          const params = Object.assign({}, this.dataForm)
          delete params.creator
          delete params._delete
          // delete params.sort
          delete params.update_time
          delete params.create_time
          let str = crmUrl()
          // console.log("params",params)
        this.$axios({
          url: `${str}/api/v1/sharePlatform/helpCenterArticle`,
          method: 'put',//可以省略 不写默认是get
          data:params,
        }).then(res => {
          console.log(res,'修改')
          this.$message.success('提交成功')
            this.$emit('submit')
            this.emitClose()
        })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.input-new-tag {
  width: 90px;
}
.el-tag {
  line-height: 32px;
  height: 32px;
  margin: 0px 5px;
}
.tips {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
}
</style>