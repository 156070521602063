<template>
  <div>
    <el-dialog top="5vh"
               title="分配订单"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               @open="open"
               :close-on-click-modal="false"
               width="657px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="匹配师："
                      prop="allocation_manage_ids">
          <el-select v-model="dataForm.allocation_manage_ids"
                     placeholder="请选择"
                     clearable
                     style="width: 100%"
                     filterable
                     @change="$forceUpdate()">
            <el-option v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="marginTop:5px;marginBottom:5px">外包权重：</div>
      <el-table :data="weight_obj_out_source"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="allocation_manage_id"
                         label="匹配师Id"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="allocation_manage_nickname"
                         label="匹配师名"
                         align="center"
                         width="155">
        </el-table-column>
        <el-table-column prop="count_in_use"
                         label="当前权重值"
                         align="center"
                         width="150">
        </el-table-column>
      </el-table>
      <div style="marginTop:20px;marginBottom:5px">代找权重：</div>
      <el-table :data="weight_obj_find_replace"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="allocation_manage_id"
                         label="匹配师Id"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="allocation_manage_nickname"
                         label="匹配师名"
                         align="center"
                         width="155">
        </el-table-column>
        <el-table-column prop="count_in_use"
                         label="当前权重值"
                         align="center"
                         width="150">
        </el-table-column>
      </el-table>
      <div style="marginTop:20px;marginBottom:5px">代签权重：</div>
      <el-table :data="weight_obj_sign_replace"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="allocation_manage_id"
                         label="匹配师Id"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="allocation_manage_nickname"
                         label="匹配师名"
                         align="center"
                         width="155">
        </el-table-column>
        <el-table-column prop="count_in_use"
                         label="当前权重值"
                         align="center"
                         width="150">
        </el-table-column>
      </el-table>

      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')"
                   slot="">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
   <script>
import { crmUrl } from '@/utils/helper.js'
import { sysAllocationWeightValue } from '@/request/api'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // manage_ids: {
    //   type: String,
    //   default: () => {
    //     return null
    //   },
    // },
    second_subject_status: {},
    type: {},
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      weight_obj_find_replace: [],
      weight_obj_out_source: [],
      weight_obj_sign_replace: [],
      dataForm: {
        allocation_manage_ids: null,
      },
      matcherId: '',

      rules: {
        allocation_manage_ids: [
          { required: true, message: '请选择匹配师', trigger: 'blur' },
        ],
      },
    }
  },

  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
  },
  methods: {
    open() {
      console.log('row', this.row)
      this.dataForm.allocation_manage_ids = this.row
        .ref_appointer_manage_user_obj
        ? this.row.ref_appointer_manage_user_obj.id
        : null

      let str = crmUrl()
      console.log('kkkkk')
      sysAllocationWeightValue().then((res) => {
        console.log('res', res)
        this.weight_obj_find_replace = res.data.data.weight_obj_find_replace
        this.weight_obj_out_source = res.data.data.weight_obj_out_source
        this.weight_obj_sign_replace = res.data.data.weight_obj_sign_replace
      })
    },
    handleToClose() {
      this.$emit('close')
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/tool/appointOrder`,
            method: 'post',
            data: {
              find_order_id: this.id,
              appointer_manage_user_id: this.dataForm.allocation_manage_ids,
            },
          }).then((res) => {
            this.$message.success('操作成功')
            this.$emit('submit')
            this.handleToClose()
            this.dataForm.allocation_manage_ids = ''
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
    
    <style lang="less" scoped>
::v-deep .el-table {
  width: 74% !important;
  margin-left: 90px;
}
</style>